import { Passengers } from '@/design-system-components/passengers-picker/passengers-picker-fields';
import {
  PassengerInformationForm,
  PassengerType,
  SecondaryPassengersForm,
} from '@/features/checkout/forms/flights-validation-schema';
import {
  FlightItinerary,
  FlightSegment,
  FlightsSearch,
} from '@/schema/flights/prices/prices.schema';
import { CreateFlightShoppingCartItem } from '@/schema/shopping-cart-item.schema';
import { FrequentFlyerType } from '@/schema/shopping-cart.schema';
import { TravelTier } from '@/schema/travel/tier.schema';
import { convertToCents } from '@/utils/number';
import { deserializedPassengersData } from '@/utils/transform-flights-params';
import { FormControlModel } from '../../ancillaries/custom-params';
import { formatInputName } from '../../ancillaries/dynamic-form';

export function generateDefaultPassengerData(
  passengerType: PassengerType,
  ancillary?: FormControlModel[],
) {
  return {
    firstName: '',
    lastName: '',
    salutation: '',
    dateOfBirth: '',
    passportRequired: false,
    passportExpiryDate: undefined,
    passportNumber: undefined,
    passportCountry: '',
    type: passengerType,
    ancillary: buildAncillariesForm(ancillary || []), // TODO check if can remove
  };
}

export function buildSecondaryPassengerForm(
  passengerParam: string,
  passengerData?: Array<PassengerInformationForm>,
  passengerAncillaries?: FormControlModel[],
): SecondaryPassengersForm {
  if (passengerData) {
    // There is always at least 1 adult passenger, so that is the primary passenger.
    return passengerData.slice(1);
  }

  const passengers = deserializedPassengersData(passengerParam);
  const secondaryPassengerArray: SecondaryPassengersForm = [];

  for (const type in passengers) {
    // this is expected cause Passengers will always have adult, child, infant
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    let passengerCount = passengers[type as keyof Passengers] as number;
    // this is expected cause Passengers will always have adult, child, infant
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const passengerType = type as 'adult' | 'child' | 'infant';

    if (passengerType === 'adult') {
      // this decreases the generation for adults by 1 because it's always the primary
      passengerCount = passengerCount - 1;
    }

    for (let i = 0; i < passengerCount; i++) {
      secondaryPassengerArray.push(
        generateDefaultPassengerData(passengerType, passengerAncillaries),
      );
    }
  }
  return secondaryPassengerArray;
}

export function buildAncillariesForm(
  ancillaries: FormControlModel[] | undefined,
): { [key: string]: string } {
  if (!ancillaries) {
    return {};
  }

  return ancillaries.reduce(
    (acc, ancillary) => {
      acc[formatInputName(ancillary.id)] = '';
      return acc;
    },
    // we need to cast this because we are returning a object
    // that is supposed to be a Record<string, string>
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    {} as { [key: string]: string },
  );
}

export const generateFlightShoppingCart = (
  itinerary: FlightItinerary,
  search: FlightsSearch | undefined,
  flightTier: TravelTier,
  tier: number,
  hasFreeformPointsInput: boolean,
): CreateFlightShoppingCartItem | undefined => {
  if (!(search && flightTier && itinerary)) {
    return;
  }

  const { originSegments, returnSegments } = filterSegmentsByType(
    itinerary.segments,
  );

  const flightShoppingCart: CreateFlightShoppingCartItem = {
    productType: 'travel_booking',
    cashAmount: convertToCents(flightTier.cashPayment),
    pointsAmount: flightTier?.pointsPayment,
    orderDetails: {
      travelType: 'flights',
      bookingKey: itinerary.bookingKey,
      tierId: tier,
      flightType: search.flightType,
      cabinClass: search.cabin,
      allSegments: itinerary.segments,
      originSegments: originSegments,
      freeformPointsEnabled: hasFreeformPointsInput,
    },
  };

  if (search?.flightType === 'rt') {
    flightShoppingCart.orderDetails.returnSegments = returnSegments;
  }
  return flightShoppingCart;
};

export const filterSegmentsByType = (
  segments: FlightSegment[],
): { originSegments: FlightSegment[]; returnSegments: FlightSegment[] } => {
  const originSegments = segments.filter(
    (segment) => segment.segmentType === 'origin',
  );
  const returnSegments = segments.filter(
    (segment) => segment.segmentType === 'return',
  );
  return { originSegments, returnSegments };
};

export const sanitizeDataText = (text: string): string => {
  return text
    .replace(/[^\s\w]/gi, '')
    .replaceAll(' ', '-')
    .toLowerCase();
};

export function formatAdditionalInformationKey(text: string) {
  return text
    .replace(/([a-z])([A-Z])/g, '$1 $2') // Add space before each uppercase letter
    .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2') // Handle acronyms
    .replace(/(\d)([A-Za-z])/g, '$1 $2') // Add space after numbers followed by letters
    .replace(/([A-Za-z])(\d)/g, '$1 $2') // Add space before numbers
    .replace(/([^\d\sA-Za-z])([A-Za-z])/g, '$1 $2') // Add space after special characters
    .replace(/([A-Z])/g, (match) => match.toLowerCase()) // Convert all uppercase to lowercase
    .replace(/^\w/, (c) => c.toUpperCase()); // Capitalize the first letter
}

export const getDefaultSelectedFrequentFlyerProgram = (
  frequentFlyerTypes: FrequentFlyerType[],
  marketingAirlineCode: string,
) => {
  return (
    frequentFlyerTypes.find((type) => type.iataCode === marketingAirlineCode)
      ?.iataCode || frequentFlyerTypes?.[0]?.iataCode
  );
};
